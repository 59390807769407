import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Main from './main';
import * as serviceWorker from './serviceWorker';

var ES6Promise = require("es6-promise");
ES6Promise.polyfill();
require('es6-object-assign').polyfill();

ReactDOM.render(
      <Main />
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
